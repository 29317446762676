@import "~@fortawesome/fontawesome-free/css/fontawesome.css";
@import "~@fortawesome/fontawesome-free/css/solid.css";
@import "~@fortawesome/fontawesome-free/css/brands.css";
@import "~@fortawesome/fontawesome-free/css/regular.css";

body{
  font-family: sans-serif;
  background-color: #FFF;
}

body.modal-open .supreme-container{
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}

body.modal-open .stickyNav{
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}

.stickyNav{
  position: sticky;
  top: 0;
  z-index: 9998;
  width: 100%;
  background-color: #FFF;
}

.nav-link{
  text-align: center;
}

.navbar{

}
.nav-link{
  text-align: center;
  font-size: 1em;
  font-weight: 550;
}

.navbar-light .navbar-nav .active .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  font-weight: bold;
  color:#FFF;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  font-weight: bold;
  color:#FFF;
}

.nav-item{
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 13rem;
}
.navbar-light .navbar-nav .nav-link{
  color:#FFF;
  font-weight: 400;
}
.navbar-light .navbar-toggler {
  border: none;
  color: #FFF;
}
.bg-light {
    background-color: #fff!important;
}

.responsive {
  width: inherit;
  max-width: 100% !important;
  height: auto !important;
}

.owl-carousel .item {
    margin: 0 !important;
    padding: 0rem !important;
}

.counters {
  text-align: center;
	background: #FFF;

	padding: 40px 20px;

  width: 80%;
  margin:auto;
}

.counters .value{
  color: #93d500;
  font-size:4vw;
  font-weight: bold;
}

.counter {
  color: #93d500;
  font-size:4vw;
  font-weight: bold;
}

.headerahorro{
  height: 250px;
  background: #93d500;

}

.headerahorro h1{
  font-size: 3vw;
  font-weight: bold;
  color: #FFF !important;
  text-align: center;
  line-height: 250px;
}

.row.is-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.is-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.is-flex .box {
  background: none;
  position: static;
}
.is-flex [class*="col-"] {
}

.h2curso {
  color: #004987 !important;
  font-size: .9em;
}

.w-5{
  display: inline;
}

.pagination{
  color: #004987;
}

.page-link{
  color: #004987;
}

.page-item.active .page-link{
  background-color: #004987;
  border-color: #004987;
}

#counters h2{
  color: #54595f;
  font-weight: bold;
  font-size: 1.5vw;
}

#counters p{
  padding-top: 2em;
  text-align: justify;
  font-size: 1em;
  color: #1f140f;
}

#video{
  background-image: url("/images/fondovideo.jpg");
	background-image: url("/images/fondovideo.jpg");
	background-image: url("/images/fondovideo.jpg");
	background-image: url("/images/fondovideo.jpg");
	background-color: #f7f7f7;
	background-position: center;
	background-attachment: fixed;
	background-size: cover;
}

#quees{
  background-color: #FFF;
}

#quees .contenedor{
  width: 80%;
  margin: auto;
}

#quees h1{
  padding: 2em;
  font-size: 2em;
  font-weight: 600;
  color: #93d500;
  text-align: center;
  line-height: 1em;
}

#quees .texto{
  padding: 3em 0;
}

#quees .texto p{
  text-align: justify;
  line-height: 1.5em;
}

#lab{
  background-image: url("/images/fondoequipo.jpg");
	background-image: url("/images/fondoequipo.jpg");
	background-image: url("/images/fondoequipo.jpg");
	background-image: url("/images/fondoequipo.jpg");
	background-color: #f7f7f7;
	background-position: center;
	background-attachment: fixed;
	background-size: cover;
}

#equipo{
  background: #FFF;
}

#equipo .header{
  height: 250px;
  background: #004987;
}

#equipo .header h1{
  font-size: 3vw;
  font-weight: bold;
  color: #FFF !important;
  text-align: center;
  line-height: 250px;
}

#comecarne{
  background-image: url("/images/fondocomecarnenoanimales.jpg");
	background-image: url("/images/fondocomecarnenoanimales.jpg");
	background-image: url("/images/fondocomecarnenoanimales.jpg");
	background-image: url("/images/fondocomecarnenoanimales.jpg");
	background-color: #f7f7f7;
	background-position: center;
	background-attachment: fixed;
	background-size: cover;
}

#prensa{
  background: #FFF;
}

#prensa .header{
  height: 250px;
  background: #004987;
}

#prensa .header h1{
  font-size: 3vw;
  font-weight: bold;
  color: #FFF !important;
  text-align: center;
  line-height: 250px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

.page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}



#prensa .nav{

}

#prensa .banner{

}

#prensa .nav-link{
  text-align: center;
}

#prensa .navbar{

}

#prensa .nav-link{
  color: #5E5E5E;
  font-weight: 500;
  margin: auto;
  font-size: .8em;
}

#prensa .nav-link.activa{
  color:white;
  font-weight: bold;
  margin: auto;
  font-size: .8em;
  background-color: none;
}

#prensa .nav-item{
  background-color: #f1f1f1;
  height: 60px;
  display:flex;align-items: center;
}

#prensa .nav-item.active{
  background-color: #004987;
  height: 60px;
  display:flex;align-items: center;
}

.ir-arriba {
	display:none;
  border-radius: 50px;
	padding:15px;
	background: #004987;
	font-size:20px;
	cursor:pointer;
	position: fixed;
	color:white;
	bottom:20px;
	right:20px;
}

@media(min-width:768px){

}

@media(max-width:1024px){
  /* #video .vid{
    width: 90%;
    height:600px;
    padding-left: 10%;
  } */
}

@media(max-width:767px){

}

@media (min-width: 1025px){
  /* #video .vid{
    width: 70%;
    height:600px;
    padding-left: 10%;
  } */
}
